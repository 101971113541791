let resumeData = {
  name: "Amogh Garg",
  role: "ML Enthusiast",
  linkedinId: "https://www.linkedin.com/in/amogh-garg/",
  skypeid: "Your skypeid",
  roleDescription: "",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/amogh-garg/",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "https://github.com/amoghgarg20",
      className: "fa fa-github",
    },
    {
      name: "twitter",
      url: "https://twitter.com/amoghgarg9",
      className: "fa fa-twitter",
    },
    {
      name: "instagram",
      url: "https://www.instagram.com/amoghgarg20/",
      className: "fa fa-instagram",
    },
  ],
  aboutme:
    "As a Software Engineer at Microsoft, my current tech stack includes working with C# and .NET. I hold a BTech in Computer Science and Engineering from Netaji Subhas University of Technology (formerly NSIT), and have a strong foundation in C++ and Python, with proficiency in data structures and algorithms and System-Design. Passionate about exploring new technologies, I am particularly interested in AI and Security of Software systems, and continuously strive to stay up-to-date with the latest advancements. Eager to contribute to the industry, I seek opportunities for further growth and learning as a software engineer.",
  address: "India",
  mobile: "+(91) 8130463841",
  email: "amoghgarg9@gmail.com",
  education: [
    {
      UniversityName: "Netaji Subhas University of Technology,New Delhi",
      specialization: "B.Tech in Computer Science and Engineering",
      YearOfPassing: "2024",
      Marks: "8.84/10",
      link: "/images/Transcript.pdf",
      name: "Transcript",
    },
    {
      UniversityName: "Amity International School, Mayur Vihar",
      specialization: "Class XII(CBSE)",
      YearOfPassing: "2020",
      Marks: "96.2%",
      link: "/images/XII Marksheet.pdf",
      name: "XII Marksheet",
    },
    {
      UniversityName: "Amity International School, Mayur Vihar",
      specialization: "Class X(CBSE)",
      YearOfPassing: "2018",
      Marks: "96.8%",
      link: "/images/X Marksheet.pdf",
      name: "X Marksheet",
    },
  ],
  internship: [
    {
      OrgName: "Microsoft India (R&D) Pvt. Ltd.",
      role: "Software Engineer",
      location: "Bengaluru",
      time: "Jul'24 - Present",
      Description:
        "Contributing to the development and enhancement of Intune, a cloud-based endpoint management product by migrating the existing system to a more robust architecture, with a focus on persistent databases and microservice architecture, ensuring enhanced reliability, security, and performance. Worked extensively with C#, .NET, and CosmosDB, leveraging deep expertise in persistent databases. Applied in-depth knowledge of security best practices throughout the software lifecycle, ensuring that all systems are designed, developed, and deployed with robust security measures.",
      Skills:
        "C#, .NET, CosmosDB, Microservice Arhcitecture, Persistent Databases, CI/CD",
    },
    {
      OrgName: "Microsoft India (R&D) Pvt. Ltd.",
      role: "SWE Intern",
      location: "Noida",
      time: "May'23 - Jul'23",
      Description:
        "Worked with Intune Data Infrastructure team under Security Org and was responsible for doing a proof of concept regarding consolidation of multiple routes and target data sources in Intune reporting pipeline. Worked on Python and Microsoft Azure services such as Azure Data Lake Storage, Microsoft Purview, Azure Synapse Analytics, Delta Parquet format, dedicated SQL pools, Spark pools, Azure Data Factory, ETL Pipelines etc.",
      Skills:
        "Azure Services, Data Engineering, Cloud, System Design, Python, Spark, Big Data handling using Delta Parquet Format.",
    },
    {
      OrgName: "Microsoft India (R&D) Pvt. Ltd.",
      role: "Mentee at Microsoft Engage Program",
      location: "Remote",
      time: "May'22 - June'22",
      Description:
        "Under the mentorship of senior developers, worked on a project which was concerned with building a browser-based application or a native mobile application demonstrating the application of Face Recognition technology.",
      Skills:
        "Python, Flask, HTML, CSS, Computer Vision, Facenet Model, Full stack development, MongoDB.",
    },
  ],
  work: [
    {
      CompanyName:
        "International Organisation of Software Developers (DEVCOMM)-NSUT",
      specialization: "Machine Learning Head",
      YearOfLeaving: "2022 - 2023",
      Description:
        "Lead a team of 10 students, worked on a project to develop a software which can be used to generate summary of webinars, mentored juniors on several ML related projects, conducted sessions and webinars on basics of ML, hosted technical fests, events and competitions related to Data-science and ML.",
    },
    {
      CompanyName:
        "International Organisation of Software Developers (DEVCOMM)-NSUT",
      specialization: "Executive Commitee Member in ML and DSA Department",
      YearOfLeaving: " 2020-2022",
      Description:
        "Worked as an individual contributor, worked on ML related projects and hosted sessions and webinars on basics of ML.",
    },
  ],
  skillsDescription: "Following are my technical skills:",
  skills: [
    {
      skillname: "C#",
    },
    {
      skillname: ".NET",
    },
    {
      skillname: "CosmosDB",
    },
    {
      skillname: "Python",
    },
    {
      skillname: "C++",
    },
    {
      skillname: "Microsoft Azure",
    },
    {
      skillname: "HTML",
    },
    {
      skillname: "CSS",
    },
    {
      skillname: "ReactJS",
    },
    {
      skillname: "SQL",
    },
    {
      skillname: "Git",
    },
    {
      skillname: "AWS",
    },
  ],
  sport_skills: [
    {
      skillname: "Cricket",
    },
    {
      skillname: "Swimming",
    },
  ],
  portfolio: [
    {
      name: "VidSum",
      description:
        "NLP system in the form of extension that automatically records online meetings/webinars/videos and generate a summary of the content in text format.",
      imgurl: "images/2.png",
      siteurl: "https://github.com/amoghgarg20/Summary-Generator",
    },
    {
      name: "Smart Login System",
      description:
        "A face login system which can be easily integrated with different web-applications for login purposes eliminating the need to remember usernames and passwords.",
      imgurl: "images/4.png",
      siteurl: "https://github.com/amoghgarg20/Login-System",
    },
    {
      name: "IOT Project",
      description:
        "A smart system which measures the hydration level of the user in real time. (Link to the research paper will be available soon)",
      imgurl: "images/2.png",
      siteurl: "/",
    },
    {
      name: "Grantify",
      description:
        "An integrated and robust online platform for the researchers providing the information and relevant details about national/international research grants. It was developed for the Grand Finale of SIH'22.",
      imgurl: "images/3.png",
      siteurl: "https://github.com/amoghgarg20/AAVSAM",
    },
  ],
  testimonials: [
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
    {
      description: "This is a sample testimonial",
      name: "Some technical guy",
    },
  ],
  album: [
    {
      description: "This is a sample testimonial",
    },
    {
      description: "This is a sample testimonial",
    },
  ],
};

export default resumeData;
